import { Injectable } from '@angular/core';
import moment from 'moment';
import { BooleanValue, DateTimeValue, ErpCustomDtoOfErpSalesOrderCustomDocumentDto, ErpCustomTransactionsDtoOfErpStockItemCustomDocumentInput, ErpFinancialSettingsInput, ErpSalesOrderCustomDocumentDto, ErpSalesOrderDetailInput, ErpSalesOrderDetailsDto, ErpSalesOrderDto, ErpSalesOrderInput, ErpStockItemCustomDocumentInput, IntValue, StringValue, UpdateOrdersDto } from 'src/app/services/api.services';
import { SalesOrderType, InventoryItemReturnOrReplacement, SalesOrderStatus, BlanketOrder, SalesOrder, SalesOrderInventoryItem, ADJUSTMENT } from '../erp-blanket-sales-order-management.data';


/**
 * This class is meant to encapsulate and isolate the logic for the Blanket Order Details page.
 * Many of the logic below is highly complicated business logic that has been developed over time.
 * The goal is to make the code more readable and maintainable by isolating the logic in this service.
 */

@Injectable({ providedIn: 'root' })
export class BlanketOrderDetailsHelperService {
    constructor() { }

    /**
     * A common scenario in our BL viz is the comparision between a child line and a blanket line.
     * The purpose is to find the blanket line that matches the child line.
     * This function below is a helper function that does this comparison, and is used in many places in the code.
     * @param candidateItem the candidate item to compare
     * @param referenceItem the reference item to compare
     * @param additionalAttributes the additional attributes to compare
     * @returns 
     */
    isMatchingItem(
        candidateItem: SalesOrderInventoryItem,
        referenceItem: SalesOrderInventoryItem,
        additionalAttributes: Partial<SalesOrderInventoryItem> = {}): boolean {
        const baseMatch =
            candidateItem.itemNo === referenceItem.itemNo &&
            candidateItem.isFree === referenceItem.isFree &&
            candidateItem.unitPrice === referenceItem.unitPrice;

        return Object.entries(additionalAttributes).every(([key, value]) => candidateItem[key] === value) && baseMatch;
    }

    // #region Create update object
    /**
     * 
     * @param originalBlanketOrderFromERP the original blanket order from ERP
     * @param originalChildSalesOrders the original child sales orders from ERP
     * @param originalBlanketOrder the original blanket order
     * @param blanketOrder the modified blanket order
     * @returns the update object to send to the .NET backend for further processing
     */
    createUpdateObject(
        originalBlanketOrderFromERP: ErpSalesOrderDto,
        originalChildSalesOrders: ErpSalesOrderDto[],
        originalBlanketOrder: BlanketOrder,
        blanketOrder: BlanketOrder
    ): UpdateOrdersDto {
        const updateOrdersDto = new UpdateOrdersDto({
            originalChildOrders: originalChildSalesOrders,
            originalBlanketOrder: originalBlanketOrderFromERP,
            modifiedBlanketOrder: new ErpSalesOrderInput({
                id: originalBlanketOrderFromERP.id,
                orderNbr: new StringValue({ value: blanketOrder.blanketOrderNumber }),
                orderType: new StringValue({ value: SalesOrderType.BL }),
                hold: originalBlanketOrderFromERP.hold,
                details: blanketOrder.inventoryItemsTotal
                    .filter(blanketLine => {
                        // Filtering for:
                        // - Items with changed quantity versus the original blanket order
                        // - New replacement items
                        // - New items
                        // - Adjustment items
                        // Removing:
                        // - Return items, they are updated through the child orders
                        const originalBlanketInventoryItem = originalBlanketOrder.inventoryItemsTotal
                            .find(originalBlanketLine => (
                                !originalBlanketLine.isAdjustment // is not adjustment
                                && originalBlanketLine.itemNo === blanketLine.itemNo // Same inventoryID
                                && originalBlanketLine.returnOrReplacement === blanketLine.returnOrReplacement // and same return/replacement type
                                && originalBlanketLine.isFree === blanketLine.isFree // and same free item status
                                && originalBlanketLine.unitPrice === blanketLine.unitPrice // and same unit price
                            ) || (
                                    originalBlanketLine.isAdjustment // is adjustment
                                    && originalBlanketLine.displayName === blanketLine.displayName // and has the same display name
                                    && originalBlanketLine.adjustmentForInventoryID === blanketLine.adjustmentForInventoryID // and is adjusting for the same item
                                )
                            )

                        if (originalBlanketInventoryItem?.id) {
                            // Filter for items with changed quantity versus the original blanket order
                            return (blanketLine.quantity !== originalBlanketInventoryItem?.quantity)
                        } else if (blanketLine.returnOrReplacement === InventoryItemReturnOrReplacement.Replacement) {
                            // In case of new replacement items, return true
                            return true
                        } else if (blanketLine.returnOrReplacement === InventoryItemReturnOrReplacement.Return
                            && !blanketLine.isAdjustment) {
                            // Remove main return item lines from blanket order update, they are updated through 
                            // the child orders
                            return false
                        }
                        else if (!blanketLine.id) {
                            // In case of new items, return true
                            return true
                        } else if (blanketLine.isAdjustment) {
                            // In case of adjustment items, return true
                            return true
                        }

                        return false
                    })
                    .map(blanketItem => {
                        let originalBlanketInventoryItem: ErpSalesOrderDetailsDto
                        if (blanketItem.isAdjustment) {
                            originalBlanketInventoryItem = originalBlanketOrderFromERP.details
                                .find(originalLine =>
                                    originalLine.inventoryID.value === ADJUSTMENT
                                    && originalLine.custom?.transactions?.usrSFReturnOrReplacement?.value === blanketItem.returnOrReplacement
                                    && originalLine.custom?.transactions?.usrSFIsAdjustment?.value === blanketItem.isAdjustment
                                    && originalLine.custom?.transactions?.usrSFAdjustmentForInventoryID?.value === blanketItem.adjustmentForInventoryID
                                )
                        } else {
                            originalBlanketInventoryItem = originalBlanketOrderFromERP.details
                                .find(originalLine =>
                                    originalLine.inventoryID.value === blanketItem.itemNo
                                    && originalLine.custom?.transactions?.usrSFReturnOrReplacement?.value === blanketItem.returnOrReplacement
                                    && originalLine.freeItem?.value === blanketItem.isFree
                                    && originalLine.unitPrice?.value === blanketItem.unitPrice
                                )
                        }

                        if (originalBlanketInventoryItem?.id) {
                            return new ErpSalesOrderDetailInput({
                                id: originalBlanketInventoryItem.id,
                                orderQty: new StringValue({ value: String(blanketItem.quantity) }),
                                inventoryID: new StringValue({ value: blanketItem.itemNo }),
                                unitPrice: new StringValue({ value: String(blanketItem.unitPrice || 0) }),
                                lineDescription: new StringValue({ value: blanketItem.displayName }),
                                custom: new ErpCustomTransactionsDtoOfErpStockItemCustomDocumentInput({
                                    transactions: new ErpStockItemCustomDocumentInput({
                                        usrSFReturnReason: new StringValue({ value: blanketItem.returnReason || '' }),
                                        usrSFReturnOrReplacement: new StringValue({ value: blanketItem.returnOrReplacement }),
                                        usrSFIsAdjustment: new BooleanValue({ value: blanketItem.isAdjustment || false }),
                                        usrSFAdjustmentForInventoryID: new StringValue({ value: blanketItem.adjustmentForInventoryID || '' })
                                    })
                                })
                            })
                        } else {
                            return new ErpSalesOrderDetailInput({
                                inventoryID: new StringValue({ value: blanketItem.itemNo }),
                                orderQty: new StringValue({ value: String(blanketItem.quantity) }),
                                unitPrice: new StringValue({ value: String(blanketItem.unitPrice || 0) }),
                                manualPrice: new BooleanValue({ value: false }),
                                lineDescription: new StringValue({ value: blanketItem.displayName }),
                                freeItem: new BooleanValue({ value: blanketItem.isFree }),
                                custom: new ErpCustomTransactionsDtoOfErpStockItemCustomDocumentInput({
                                    transactions: new ErpStockItemCustomDocumentInput({
                                        usrSFReturnReason: new StringValue({ value: blanketItem.returnReason || '' }),
                                        usrSFReturnOrReplacement: new StringValue({ value: blanketItem.returnOrReplacement }),
                                        usrSFIsAdjustment: new BooleanValue({ value: blanketItem.isAdjustment || false }),
                                        usrSFAdjustmentForInventoryID: new StringValue({ value: blanketItem.adjustmentForInventoryID || '' })
                                    })
                                })
                            })
                        }
                    }),
                custom: new ErpCustomDtoOfErpSalesOrderCustomDocumentDto({
                    document: new ErpSalesOrderCustomDocumentDto({
                        usrSFDeliveryCounter: new IntValue({ value: blanketOrder.numberOfDeliveries })
                    })
                })
            }),
            modifiedChildOrders: blanketOrder.salesOrders
                .filter(order => order.salesOrderNumber.length
                    && (order.status === SalesOrderStatus.OnHold
                        || order.status === SalesOrderStatus.CreditHold
                        || order.status === SalesOrderStatus.Open
                    ))
                .map(order => {
                    return new ErpSalesOrderInput({
                        id: originalChildSalesOrders.find(o => o.orderNbr.value === order.salesOrderNumber).id,
                        orderNbr: new StringValue({ value: order.salesOrderNumber }),
                        orderType: new StringValue({ value: order.type }),
                        customerID: new StringValue({ value: blanketOrder.customer }),
                        requestedOn: new DateTimeValue({ value: moment(order.requestDate) }),
                        details: order.inventoryItems
                            .filter(childLine => { // Filtering for items with changed quantity versus the original child sales order to update
                                const existingItem = originalChildSalesOrders
                                    .find(o => o.orderNbr.value === order.salesOrderNumber)
                                    .details.find(this.isExistingItem(childLine))

                                if (existingItem?.id) {
                                    // Filter for items with changed quantity versus the original child sales order
                                    return childLine.quantity !== existingItem?.orderQty.value
                                } else if (childLine.returnOrReplacement === InventoryItemReturnOrReplacement.Replacement
                                    || childLine.returnOrReplacement === InventoryItemReturnOrReplacement.Return) {
                                    // In case of new return or replacement items, return true
                                    // Adjustments are also return type, so they are included here
                                    return true
                                } else if (!childLine.id) {
                                    // In case of new items, return true
                                    return true
                                }

                                return false
                            })
                            .map(childLine => {
                                const existingItem = originalChildSalesOrders
                                    .find(o => o.orderNbr.value === order.salesOrderNumber)
                                    .details.find(this.isExistingItem(childLine))

                                if (existingItem?.id) {
                                    return new ErpSalesOrderDetailInput({
                                        orderQty: new StringValue({ value: String(childLine.quantity) }),
                                        id: existingItem.id,
                                        inventoryID: new StringValue({ value: childLine.itemNo }),
                                        lineDescription: new StringValue({ value: childLine.displayName }),
                                        unitPrice: new StringValue({ value: String(childLine.unitPrice || 0) }),
                                        custom: new ErpCustomTransactionsDtoOfErpStockItemCustomDocumentInput({
                                            transactions: new ErpStockItemCustomDocumentInput({
                                                usrSFReturnOrReplacement: new StringValue({ value: childLine.returnOrReplacement }),
                                                usrSFReturnReason: new StringValue({ value: childLine.returnReason }),
                                                usrSFAdjustmentForInventoryID: new StringValue({ value: childLine.adjustmentForInventoryID || '' }),
                                                usrSFIsAdjustment: new BooleanValue({ value: childLine.isAdjustment || false })
                                            })
                                        })
                                    })
                                } else {
                                    return new ErpSalesOrderDetailInput({
                                        inventoryID: new StringValue({ value: childLine.itemNo }),
                                        orderQty: new StringValue({ value: String(childLine.quantity) }),
                                        unitPrice: new StringValue({ value: String(childLine.unitPrice || 0) }),
                                        manualPrice: new BooleanValue({ value: false }),
                                        lineDescription: new StringValue({ value: childLine.displayName }),
                                        freeItem: new BooleanValue({ value: childLine.isFree }),
                                        custom: new ErpCustomTransactionsDtoOfErpStockItemCustomDocumentInput({
                                            transactions: new ErpStockItemCustomDocumentInput({
                                                usrSFReturnOrReplacement: new StringValue({ value: childLine.returnOrReplacement }),
                                                usrSFReturnReason: new StringValue({ value: childLine.returnReason }),
                                                usrSFAdjustmentForInventoryID: new StringValue({ value: childLine.adjustmentForInventoryID || '' }),
                                                usrSFIsAdjustment: new BooleanValue({ value: childLine.isAdjustment || false })
                                            })
                                        })
                                    })
                                }
                            })
                    })
                }),
            newChildOrders: blanketOrder.salesOrders
                .filter(order => !order.salesOrderNumber.length)
                .map(order => {
                    return new ErpSalesOrderInput({
                        orderType: new StringValue({ value: SalesOrderType.SO }),
                        customerID: new StringValue({ value: blanketOrder.customer }),
                        requestedOn: new DateTimeValue({ value: moment(order.requestDate) }),
                        details: order.inventoryItems.map(item => {
                            return new ErpSalesOrderDetailInput({
                                inventoryID: new StringValue({ value: item.itemNo }),
                                orderQty: new StringValue({ value: String(item.quantity) }),
                                unitPrice: new StringValue({ value: String(item.unitPrice) }),
                                manualPrice: new BooleanValue({ value: false }),
                                freeItem: new BooleanValue({ value: item.isFree })
                            })
                        }),
                        financialSettings: new ErpFinancialSettingsInput({
                            terms: new StringValue({ value: 'PRELEVEMEN' }),
                        }),
                    })
                })
        })

        return updateOrdersDto
    }

    private isExistingItem(childLine: SalesOrderInventoryItem): (value: ErpSalesOrderDetailsDto, index: number, obj: ErpSalesOrderDetailsDto[]) => boolean {
        return originalChildLine => {
            if (childLine.isAdjustment) {
                return originalChildLine.inventoryID.value === ADJUSTMENT // Same inventoryID
                    && !!originalChildLine.custom?.transactions?.usrSFIsAdjustment?.value === !!childLine.isAdjustment // and same adjustment type
                    && originalChildLine.custom?.transactions?.usrSFReturnOrReplacement?.value === childLine.returnOrReplacement // and same return/replacement type
                    && originalChildLine.custom?.transactions?.usrSFAdjustmentForInventoryID?.value === childLine.adjustmentForInventoryID; // and same adjustment for inventory ID
            } else if (childLine.isFree) {
                return originalChildLine.inventoryID.value === childLine.itemNo // Same inventoryID
                    && originalChildLine.freeItem?.value === childLine.isFree; // and is a free item
            } else if (childLine.returnOrReplacement === InventoryItemReturnOrReplacement.Return
                || childLine.returnOrReplacement === InventoryItemReturnOrReplacement.Replacement) {
                return originalChildLine.inventoryID.value === childLine.itemNo // Same inventoryID
                    && originalChildLine.custom?.transactions?.usrSFReturnOrReplacement?.value === childLine.returnOrReplacement // and same return/replacement type
                    && !originalChildLine.custom?.transactions?.usrSFIsAdjustment?.value; // and not an adjustment type 
            } else {
                // Normal line item
                return originalChildLine.inventoryID.value === childLine.itemNo // Same inventoryID
                    && originalChildLine.unitPrice?.value === childLine.unitPrice // and same unit price
                    && !originalChildLine.custom?.transactions?.usrSFReturnOrReplacement?.value // and not return/replacement type
                    && !originalChildLine.custom?.transactions?.usrSFIsAdjustment?.value // and not an adjustment type
                    && !originalChildLine.freeItem?.value;
            }
        };
    }

    // #endregion

    // #region Check for shipped deliveries
    isDeliveryShipped(delivery: SalesOrder) {
        return delivery.type == SalesOrderType.IM
            || delivery.status === SalesOrderStatus.Shipping
            || delivery.status === SalesOrderStatus.Open
            || delivery.status === SalesOrderStatus.Completed
            || delivery.status === SalesOrderStatus.WaitingForRoute
            || delivery.status === SalesOrderStatus.Route
    }
    // #endregion

    // #region Distribute inventory items
    distributeInventoryItems(blanketOrder: BlanketOrder): SalesOrder[] {
        const shippedOrders = blanketOrder.salesOrders.filter(order => this.isDeliveryShipped(order))
        const numberOfDeliveriesLeft = blanketOrder.numberOfDeliveries - shippedOrders.length
        // Filter out shipped orders
        const openSalesOrders = blanketOrder.salesOrders.filter(order =>
            !this.isDeliveryShipped(order)
        )

        // Create new sales orders if there are not enough open orders
        for (let i = openSalesOrders.length; i < numberOfDeliveriesLeft; i++) {
            openSalesOrders.push({
                salesOrderNumber: '',
                status: SalesOrderStatus.OnHold,
                inventoryItems: []
            })
        }

        // Distribute the remaining quantities among the open orders
        for (let i = 0; i < blanketOrder.inventoryItemsTotal.length; i++) {
            const blanketLineItem = blanketOrder.inventoryItemsTotal[i]

            // If the item is a return or replacement, skip it
            if (blanketLineItem.returnOrReplacement) {
                continue
            }

            // If the item's total quantity is 0, skip it
            if (blanketLineItem.quantity === 0) {
                continue
            }

            // Calculate the total quantity of the item in shipped orders
            const shippedQuantities = shippedOrders.reduce((acc, shippedOrder) => {
                const item = shippedOrder.inventoryItems.find(i =>
                    this.isMatchingItem(i, blanketLineItem)
                )
                return acc + (item ? item.quantity : 0)
            }, 0)

            // Calculate the base quantity to distribute among open orders
            const remainingQuantity = blanketLineItem.quantity - shippedQuantities
            const baseQuantity = Math.floor(remainingQuantity / numberOfDeliveriesLeft)

            // Calculate the remainder to distribute among open orders
            let remainder = remainingQuantity % numberOfDeliveriesLeft

            // Distribute quantities only among open orders
            for (let j = 0; j < openSalesOrders.length; j++) {
                const salesOrder = openSalesOrders[j]
                const quantityToDistribute = baseQuantity + (remainder > 0 ? 1 : 0)
                remainder -= remainder > 0 ? 1 : 0

                // Find existing inventory item in the sales order or create a new one
                let existingItem = salesOrder.inventoryItems.find(childLine =>
                    this.isMatchingItem(childLine, blanketLineItem)
                )

                if (existingItem) {
                    existingItem.quantity = quantityToDistribute
                } else {
                    salesOrder.inventoryItems.push({
                        itemNo: blanketLineItem.itemNo,
                        displayName: blanketLineItem.displayName,
                        quantity: quantityToDistribute,
                        unitPrice: blanketLineItem.unitPrice,
                        isFree: blanketLineItem.isFree,
                        returnOrReplacement: blanketLineItem.returnOrReplacement
                    } as SalesOrderInventoryItem)
                }
            }
        }

        // return both shipped and open orders
        const allOrders = shippedOrders.concat(openSalesOrders)

        return allOrders
    }

    autoDistributeFromTotalQuantity(blanketOrder: BlanketOrder, blanketLineItem: SalesOrderInventoryItem) {
        // Calculate the total quantity of the item in shipped orders
        const shippedQuantity = blanketOrder.salesOrders.filter(order => this.isDeliveryShipped(order))
            .map(order => order.inventoryItems
                .find(i => i.itemNo === blanketLineItem.itemNo
                    && i.unitPrice === blanketLineItem.unitPrice
                    && i.isFree === blanketLineItem.isFree
                    && i.returnOrReplacement === blanketLineItem.returnOrReplacement
                )?.quantity || 0
            )
            .reduce((acc, quantity) => {
                return acc + quantity
            }, 0)

        // Calculate the remaining quantity to distribute among the subsequent orders
        const remainingQuantityToDistributeToSubsequentOrders = blanketOrder.inventoryItemsTotal
            .find(i => this.isMatchingItem(i, blanketLineItem, { returnOrReplacement: blanketLineItem.returnOrReplacement })
            )
            .quantity - shippedQuantity

        // Distribute the remaining quantity among the subsequent orders
        const subsequentOrders = blanketOrder.salesOrders.filter(order => !this.isDeliveryShipped(order))
        const baseQuantity = Math.floor(remainingQuantityToDistributeToSubsequentOrders / subsequentOrders.length)
        let remainder = remainingQuantityToDistributeToSubsequentOrders % subsequentOrders.length

        for (let i = 0; i < subsequentOrders.length; i++) {
            const order = subsequentOrders[i]
            const quantityToDistribute = baseQuantity + (remainder > 0 ? 1 : 0)
            remainder -= remainder > 0 ? 1 : 0

            const item = order.inventoryItems
                .find(i => {
                    if (i.itemNo === blanketLineItem.itemNo
                        && i.unitPrice === blanketLineItem.unitPrice
                        && i.returnOrReplacement === blanketLineItem.returnOrReplacement
                        && !blanketLineItem.isAdjustment) {
                        // If we find the matching line item and it's not an adjustment line
                        // Then we want to distribute and update the quantity
                        return true
                    }

                    return false
                }
                )
            // If the item exists in the order, update the quantity
            if (item) {
                item.quantity = quantityToDistribute
            }
        }
    }

    autoDistributeFromTotalQuantityForReturnLine(blanketOrder: BlanketOrder, blanketLineItem: SalesOrderInventoryItem) {
        // Calculate the total quantity of the item in shipped orders
        const shippedQuantity = blanketOrder.salesOrders.filter(order => this.isDeliveryShipped(order))
            .map(order => order.inventoryItems
                .find(i => i.itemNo === blanketLineItem.itemNo
                    && i.returnOrReplacement === blanketLineItem.returnOrReplacement
                    && !i.isAdjustment
                )?.quantity || 0
            )
            .reduce((acc, quantity) => {
                return acc + quantity
            }, 0)

        // Calculate the remaining quantity to distribute among the subsequent orders
        const remainingQuantityToDistributeToSubsequentOrders = blanketOrder.inventoryItemsTotal
            .find(i => i.itemNo === blanketLineItem.itemNo
                && i.returnOrReplacement === blanketLineItem.returnOrReplacement
            )
            .quantity - shippedQuantity

        // Distribute the remaining quantity among the subsequent orders
        // Instead of even distribution, distribute to the first unshipped order
        const nextOrder = blanketOrder.salesOrders.find(order => !this.isDeliveryShipped(order))
        if (nextOrder) {
            const item = nextOrder.inventoryItems
                .find(i => {
                    if (i.itemNo === blanketLineItem.itemNo
                        && i.returnOrReplacement === blanketLineItem.returnOrReplacement
                        && !blanketLineItem.isAdjustment) {
                        // If we find the matching line item and it's not an adjustment line
                        // Then we want to distribute and update the quantity
                        return true
                    }

                    return false
                }
                )
            // If the item exists in the order, update the quantity
            if (item) {
                item.quantity = remainingQuantityToDistributeToSubsequentOrders

                // Then find the assocated adjustment line and update its quantity
                const adjustmentItem = nextOrder.inventoryItems
                    .find(i => i.itemNo === ADJUSTMENT
                        && i.returnOrReplacement === blanketLineItem.returnOrReplacement
                        && i.adjustmentForInventoryID === blanketLineItem.itemNo
                    )
                if (adjustmentItem) {
                    adjustmentItem.quantity = remainingQuantityToDistributeToSubsequentOrders
                }
            }
        }
    }

    autoDistributeFromTotalQuantityForFreeLine(blanketOrder: BlanketOrder, blanketLineItem: SalesOrderInventoryItem) {
        // Calculate the total quantity of the item in shipped orders
        const shippedQuantity = blanketOrder.salesOrders.filter(order => this.isDeliveryShipped(order))
            .map(order => order.inventoryItems
                .find(i =>
                    this.isMatchingItem(i, blanketLineItem)
                )?.quantity || 0
            )
            .reduce((acc, quantity) => {
                return acc + quantity
            }, 0)

        // Calculate the remaining quantity to distribute among the subsequent orders
        const remainingQuantityToDistributeToSubsequentOrders = blanketOrder.inventoryItemsTotal
            .find(i =>
                this.isMatchingItem(i, blanketLineItem)
            )
            .quantity - shippedQuantity

        // Distribute the remaining quantity among the subsequent orders
        const subsequentOrders = blanketOrder.salesOrders.filter(order => !this.isDeliveryShipped(order))
        const baseQuantity = Math.floor(remainingQuantityToDistributeToSubsequentOrders / subsequentOrders.length)
        let remainder = remainingQuantityToDistributeToSubsequentOrders % subsequentOrders.length

        for (let i = 0; i < subsequentOrders.length; i++) {
            const order = subsequentOrders[i]
            const quantityToDistribute = baseQuantity + (remainder > 0 ? 1 : 0)
            remainder -= remainder > 0 ? 1 : 0

            const item = order.inventoryItems
                .find(i => {
                    if (this.isMatchingItem(i, blanketLineItem)) {
                        return true
                    }

                    return false
                }
                )
            // If the item exists in the order, update the quantity
            if (item) {
                item.quantity = quantityToDistribute
            } else {
                order.inventoryItems.push({
                    itemNo: blanketLineItem.itemNo,
                    displayName: blanketLineItem.displayName,
                    quantity: quantityToDistribute,
                    isFree: true,
                    unitPrice: blanketLineItem.unitPrice,
                })
            }
        }
    }
    // #endregion

    // #region Reconciliation for blanket orders
    /**
     * 
     * @param blanketOrder the blanket order to reconcile
     * @returns itemNos as string[] that need reconciliation
     */
    findLinesThatNeedReconciliation(blanketOrder: BlanketOrder): SalesOrderInventoryItem[] {
        // Find blanket line without child lines
        // by comparing the blanket order's total inventory items with the child orders' inventory items
        // If a blanket line is not found in any of the child orders, reconciliation is required
        const blanketLinesWithoutChildLines = blanketOrder.inventoryItemsTotal
            .filter(blanketLine => !blanketLine.isAdjustment || !blanketLine.returnOrReplacement) // Filter out adjustment and return/replacement lines
            .filter(blanketLine => // at this stage we should have main and free lines only
                !blanketOrder.salesOrders.some(order => order.inventoryItems
                    .some(childLine =>
                        this.isMatchingItem(childLine, blanketLine)
                    ))
            )

        return blanketLinesWithoutChildLines;
    }

    reconcileBlanketOrder(originalBlanketOrderFromERP: ErpSalesOrderDto,
        originalChildSalesOrders: ErpSalesOrderDto[],
        originalBlanketOrder: BlanketOrder,
        blanketOrder: BlanketOrder,
        itemNosOfLinesThatNeedReconciliation: SalesOrderInventoryItem[]): UpdateOrdersDto {

        // For each child order in blanketOrder, add the missing item nos with quantity 0
        // The idea is: for each item nos that need reconciliation, we add the missing child items into the child orders
        // This way, the child orders will have the same items as the blanket order
        // And the webhook will link the child order lines to the blanket order lines

        for (let i = 0; i < blanketOrder.salesOrders.length; i++) {
            const order = blanketOrder.salesOrders[i];
            for (let j = 0; j < itemNosOfLinesThatNeedReconciliation.length; j++) {
                const line = itemNosOfLinesThatNeedReconciliation[j];
                order.inventoryItems.push({
                    itemNo: line.itemNo,
                    quantity: 0,
                    isFree: line.isFree,
                    unitPrice: line.unitPrice,
                } as SalesOrderInventoryItem);
            }
        }

        return this.createUpdateObject(
            originalBlanketOrderFromERP,
            originalChildSalesOrders,
            originalBlanketOrder,
            blanketOrder
        )
    }
    // #endregion
}