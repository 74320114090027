import { Routes, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { ClientPortalLayoutComponent } from './layouts/client-portal/client-portal-layout.component';
import { Injectable } from '@angular/core';
import { UserType } from 'src/app/services/api.services';
import { AccountManageService } from './services/accountManage.service';

@Injectable()
export class EmployeeAuthGuardCanActivate {
    constructor(private router: Router, private accountManageService: AccountManageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if (this.accountManageService.isLoggedIn && this.accountManageService.userType === UserType.Employee) {
            return true;
        }
        if (this.accountManageService.isLoggedIn && this.accountManageService.userType === UserType.Client) {
            this.router.navigate(['/portal']);
        }
        return false;
    }
}

@Injectable()
export class ClientAuthGuardCanActivate {
    constructor(private router: Router, private accountManageService: AccountManageService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if (this.accountManageService.isLoggedIn && this.accountManageService.userType === UserType.Client) {
            return true;
        }

        return false;
    }
}

export const AppRoutes: Routes = [
    {
        path: 'portal',
        component: ClientPortalLayoutComponent,
        canActivate: [ClientAuthGuardCanActivate],
        children: [
            {
                path: '',
                loadChildren: () => import('./client-portal-pages/client-portal-pages.module').then((m) => m.ClientPortalPagesModule),
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [EmployeeAuthGuardCanActivate],
        // canActivateChild: [NgxPermissionsGuard],
        // canLoad: [NgxPermissionsGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./admin-pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
                data: {
                    permissions: {
                        only: 'ViewCrmDashboard',
                    },
                },
            },
            {
                path: 'employees',
                loadChildren: () => import('./admin-pages/employees/employees.module').then((m) => m.EmployeesModule),
                data: {
                    permissions: {
                        only: 'ViewEmployee',
                    },
                },
            },
            {
                path: 'employee-profile',
                loadChildren: () => import('./admin-pages/employee-profile/employee-profile.module').then((m) => m.EmployeeProfileModule),
            },
            {
                path: 'client-leads',
                loadChildren: () => import('./admin-pages/client-leads/client-leads.module').then((m) => m.ClientLeadsModule),
            },
            {
                path: 'callbacks',
                loadChildren: () => import('./admin-pages/callbacks/callbacks.module').then((m) => m.CallbacksModule),
            },
            {
                path: 'products',
                loadChildren: () => import('./admin-pages/products/products.module').then((m) => m.ProductsModule),
            },
            {
                path: 'categories',
                loadChildren: () => import('./admin-pages/categories/categories.module').then((m) => m.CategoriesModule),
            },
            {
                path: 'menus',
                loadChildren: () => import('./admin-pages/menus/menus.module').then((m) => m.MenusModule),
            },
            {
                path: 'productgroups',
                loadChildren: () => import('./admin-pages/product-groups/product-groups.module').then((m) => m.ProductGroupsModule),
            },
            {
                path: 'referralcatalog',
                loadChildren: () => import('./admin-pages/referral-catalog/referral-catalog.module').then((m) => m.ReferralCatalogModule),
            },
            {
                path: 'appointments',
                loadChildren: () => import('./admin-pages/appointments/appointments.module').then((m) => m.AppointmentsModule),
            },
            {
                path: 'appointment-management',
                loadChildren: () => import('./admin-pages/appointment-management/appointment-management.module').then((m) => m.AppointmentManagementModule),
                data: {
                    permissions: {
                        except: 'ViewAssignedAppointments',
                    },
                },
            },
            {
                path: 'sales-rep-appointments',
                loadChildren: () => import('./admin-pages/appointment-sales-rep/appointment-sales-rep.module').then((m) => m.AppointmentSalesRepModule),
                data: {
                    permissions: {
                        only: 'ViewAssignedAppointments',
                    },
                },
            },
            {
                path: 'appointment-qualifications',
                loadChildren: () => import('./admin-pages/appointment-qualifications/appointment-qualifications.module').then((m) => m.AppointmentQualificationsModule),
                data: {
                    permissions: {
                        only: 'ViewAppointmentQualification',
                    },
                },
            },
            {
                path: 'test',
                loadChildren: () => import('./test/test/test.module').then((m) => m.TestModule),
            },
            {
                path: 'freezers',
                loadChildren: () => import('./admin-pages/freezers/freezers.module').then((m) => m.FreezersModule),
            },
            {
                path: 'freezer-regions',
                loadChildren: () => import('./admin-pages/freezer-regions/freezer-regions.module').then((m) => m.FreezerRegionsModule),
            },
            {
                path: 'view-appointment-details',
                loadChildren: () =>
                    import('./admin-pages/appointment-details-email-display/appointment-details-email-display.module').then((m) => m.AppointmentDetailsEmailDisplayModule),
                data: {
                    permissions: {
                        only: 'ViewAppointmentEmailDetails',
                    },
                },
            },
            {
                path: 'login-page-management',
                loadChildren: () => import('./admin-pages/login-page-management/login-page-management-form.module').then((m) => m.LoginPageManagementModule),
                data: {
                    permissions: {
                        only: 'EditLoginPage',
                    },
                },
            },
            {
                path: 'service-tickets',
                loadChildren: () => import('./admin-pages/service-tickets/service-tickets.module').then((m) => m.ServiceTicketsModule),
                data: {
                    permissions: {
                        only: 'ViewServiceTickets',
                    },
                },
            },
            {
                path: 'client-next-delivery-task',
                loadChildren: () => import('./admin-pages/client-next-delivery-task/client-next-delivery-task.module').then((m) => m.ClientNextDeliveryTaskModule),
                data: {
                    permissions: {
                        only: 'ViewAssignedNextDeliveryTask',
                    },
                },
            },
            {
                path: 'sales-rep-dashboard',
                loadChildren: () => import('./admin-pages/sales-rep-dashboard/sales-rep-dashboard.module').then((m) => m.SalesRepDashboardModule),
                data: {
                    permissions: {
                        only: 'ViewSalesDashboard',
                    },
                },
            },
            {
                path: 'tm-dashboard',
                loadChildren: () => import('./admin-pages/tm-dashboard/tm-dashboard.module').then((m) => m.TMDashboardModule),
                data: {
                    permissions: {
                        only: 'ViewTMDashboard',
                    },
                },
            },
            {
                path: 'federal-dncl',
                loadChildren: () => import('./admin-pages/federal-dncl/federal-dncl.module').then((m) => m.FederalDnclModule),
                data: {
                    permissions: {
                        only: 'ViewDnclList',
                    },
                },
            },
            {
                path: 'local-dncl',
                loadChildren: () => import('./admin-pages/local-dncl/local-dncl.module').then((m) => m.LocalDnclModule),
                data: {
                    permissions: {
                        only: 'ViewLocalDnclList',
                    },
                },
            },
            {
                path: 'campaigns',
                loadChildren: () => import('./admin-pages/campaigns/campaigns.module').then((m) => m.CampaignsModule),
            },
            {
                path: 'credit-task-campaigns',
                loadChildren: () => import('./admin-pages/credit-task-campaign/credit-task-campaign.module').then((m) => m.CreditTaskCampaignsModule),
            },
            {
                path: 'powerbi',
                loadChildren: () => import('./admin-pages/power-bi/powerbi.module').then((m) => m.PowerBIModule),
            },
            {
                path: 'notification-configuration',
                loadChildren: () => import('./admin-pages/notification-configuration/notification-configuration.module').then((m) => m.NotificationConfigurationModule),
            },
            {
                path: 'client-delivery-schedule',
                loadChildren: () => import('./admin-pages/client-delivery-schedule/client-delivery-schedule.module').then((m) => m.ClientDeliveryScheduleModule),
            },
            {
                path: 'circuit-order',
                loadChildren: () => import('./admin-pages/circuit-order/circuit-order.module').then((m) => m.CircuitOrderModule),
            },
            {
                path: 'sales-rep-schedule',
                loadChildren: () => import('./admin-pages/schedule-management/sales-rep-schedule/sales-rep-schedule.module').then((m) => m.SalesRepScheduleModule),
            },
            {
                path: 'regional-visualization',
                loadChildren: () => import('./admin-pages/schedule-management/regional-visualization/regional-visualization.module').then((m) => m.RegionalVisualizationModule),
            },
            {
                path: 'department-configs',
                loadChildren: () => import('./admin-pages/twilio-call-flow-management/department-configs/department-configs.module').then((m) => m.DepartmentConfigsModule),
            },
            {
                path: 'global-call-center-config',
                loadChildren: () => import('./admin-pages/twilio-call-flow-management/global-call-center-config/global-call-center-config.module').then((m) => m.GlobalCallCenterConfigModule),
            },
            {
                path: 'statutory-holidays',
                loadChildren: () => import('./admin-pages/twilio-call-flow-management/statutory-holidays/statutory-holidays.module').then((m) => m.StatutoryHolidaysModule),
            },
            {
                path: 'optimo-config',
                loadChildren: () => import('./admin-pages/optimo-config/optimo-config.module').then((m) => m.OptimoConfigModule),
            },
            {
                path: 'erp-blanket-sales-order-management',
                loadChildren: () => import('./admin-pages/erp-blanket-sales-order-management/erp-blanket-sales-order-management.module').then((m) => m.ErpBlanketSalesOrderManagementModule),
                data: {
                    permissions: {
                        only: 'ViewERPSalesOrder',
                    },
                },
            },
            {
                path: 'erp-export-order-management',
                loadChildren: () => import('./admin-pages/erp-export-order-management/erp-export-order-management.module').then((m) => m.ErpExportOrderManagementModule),
                data: {
                    permissions: {
                        only: 'ViewERPSalesOrder',
                    },
                },
            },
            {
                path: 'erp-payment-stress-tester',
                loadChildren: () => import('./admin-pages/erp-payment-stress-tester/erp-payment-stress-tester.module').then((m) => m.ErpPaymentStressTesterModule),
                data: {
                    permissions: {
                        only: 'ViewERPSalesOrder',
                    },
                },
            },
            {
                path: 'sms-sender',
                loadChildren: () => import('./admin-pages/sms-sender/sms-sender.module').then((m) => m.SmsSenderModule),
                data: {
                    permissions: {
                        only: 'ViewSmsSender',
                    },
                }
            },
        ],
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
            },
        ],
    },
];
