import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-blanket-order-quantity-distribution-choice-dialog',
  templateUrl: './blanket-order-quantity-distribution-choice-dialog.component.html',
  styleUrl: './blanket-order-quantity-distribution-choice-dialog.component.scss'
})
export class BlanketOrderQuantityDistributionChoiceDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<BlanketOrderQuantityDistributionChoiceDialogComponent>
  ) { }

  onManual() {
    this.dialogRef.close('manual');
  }
  onAuto() {
    this.dialogRef.close('auto');
  }
  onCancel() {
    this.dialogRef.close('cancel');
  }
}
