import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogResult } from '../../../models/dialogResult';
import { PushToOptimorouteDialogComponent } from './push-to-optimoroute-dialog.component';

abstract class OptimoBaseError {
    message: string;
    constructor(message: string) {
        this.message = message;
    }
}

export class NotFoundOptimoError extends OptimoBaseError {
    constructor() {
        super("ERP_EXPORT_ORDER_MANAGEMENT.ERROR.NO_ORDER_PUSHED");
    }
}

export class UndefinedOptimoError extends OptimoBaseError {
    constructor(message: string) {
        super(message);
    }
}

@Injectable({
    providedIn: 'root',
})
export class PushToOptimorouteDialogError {
    static getError(error: string) {
        if (error.includes("Sales orders with status AttenteDeRoute")) {
            return new NotFoundOptimoError();
        }
        return new UndefinedOptimoError(error);
    }
}
