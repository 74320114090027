import { NgModule } from '@angular/core';
import { BlanketOrderNewItemOptionsDialogComponent } from './blanket-order-new-item-options-dialog.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule } from '@angular/forms';


@NgModule({
    declarations: [
        BlanketOrderNewItemOptionsDialogComponent
    ],
    imports: [
        CommonModule, MaterialModule, TranslateModule, FormsModule
    ],
    exports: [BlanketOrderNewItemOptionsDialogComponent]
})
export class BlanketOrderNewItemOptionsDialogModuleModule { }
