import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { BlanketOrder, SalesOrderInventoryItem } from 'src/app/admin-pages/erp-blanket-sales-order-management/erp-blanket-sales-order-management.data'

@Component({
  selector: 'app-blanket-order-modification-preview-dialog',
  templateUrl: './blanket-order-modification-preview-dialog.component.html',
  styleUrls: ['./blanket-order-modification-preview-dialog.component.scss']
})
export class BlanketOrderModificationPreviewDialogComponent implements OnInit {

  originalBlanketOrder: BlanketOrder
  modifiedBlanketOrder: BlanketOrder

  changes: BlanketOrderChanges = {
    addedItems: [],
    removedItems: [],
    modifiedItems: []
  }

  constructor(
    private dialogRef: MatDialogRef<BlanketOrderModificationPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { originalBlanketOrder: BlanketOrder, modifiedBlanketOrder: BlanketOrder }
  ) {
    if (this.data) {
      this.originalBlanketOrder = this.data.originalBlanketOrder
      this.modifiedBlanketOrder = this.data.modifiedBlanketOrder
    }
  }

  ngOnInit(): void {
    this.compareBlanketOrders()
  }

  private compareBlanketOrders() {
    const originalItems = this.originalBlanketOrder.inventoryItemsTotal || [];
    const modifiedItems = this.modifiedBlanketOrder.inventoryItemsTotal || [];

    // Identify added items in the modified order that are not in the original order
    this.changes.addedItems = modifiedItems.filter(modifiedItem => {
      let originalItem
      if (modifiedItem.isAdjustment) {
        originalItem = originalItems.find(originalItem =>
          originalItem.isAdjustment &&
          originalItem.adjustmentForInventoryID === modifiedItem.adjustmentForInventoryID
          && originalItem.associatedSONumber === modifiedItem.associatedSONumber
        )
      } else {
        originalItem = originalItems.find(originalItem =>
          originalItem.itemNo === modifiedItem.itemNo
          && originalItem.returnOrReplacement === modifiedItem.returnOrReplacement
          && originalItem.isFree === modifiedItem.isFree
          && originalItem.unitPrice === modifiedItem.unitPrice
        )
      }
      return !originalItem;
    }
    );

    // Identify modified items by comparing quantities and unit prices between original and modified orders
    this.changes.modifiedItems = modifiedItems
      .map(modifiedItem => {
        let originalItem
        if (modifiedItem.isAdjustment) {
          originalItem = originalItems.find(originalItem =>
            originalItem.isAdjustment &&
            originalItem.adjustmentForInventoryID === modifiedItem.adjustmentForInventoryID
            && originalItem.associatedSONumber === modifiedItem.associatedSONumber
          )
        } else {
          originalItem = originalItems.find(originalItem =>
            originalItem.itemNo === modifiedItem.itemNo &&
            originalItem.returnOrReplacement === modifiedItem.returnOrReplacement
            && originalItem.isFree === modifiedItem.isFree
            && originalItem.unitPrice === modifiedItem.unitPrice
          )
        }

        const hasQuantityChanged = originalItem && originalItem.quantity !== modifiedItem.quantity;
        const hasUnitPriceChanged = originalItem && originalItem.unitPrice !== modifiedItem.unitPrice;

        if (originalItem && (hasQuantityChanged || hasUnitPriceChanged)) {
          return {
            itemNo: modifiedItem.itemNo,
            displayName: modifiedItem.displayName,
            returnOrReplacement: modifiedItem.returnOrReplacement,
            isFree: modifiedItem.isFree,
            quantityBefore: originalItem.quantity,
            quantityAfter: modifiedItem.quantity,
            unitPriceBefore: originalItem.unitPrice,
            unitPriceAfter: modifiedItem.unitPrice
          };
        }
      })
      .filter(item => item !== undefined) as SalesOrderInventoryModifiedItem[];

    // Calculate total quantities before and after, excluding return or replacement items
    this.changes.totalQuantityBefore = originalItems
      .filter(item => !item.returnOrReplacement)
      .reduce((totalQuantity, item) => totalQuantity + item.quantity, 0);

    this.changes.totalQuantityAfter = modifiedItems
      .filter(item => !item.returnOrReplacement)
      .reduce((totalQuantity, item) => totalQuantity + item.quantity, 0);

    // Set total cost values for before and after
    this.changes.totalCostBefore = this.originalBlanketOrder.orderTotal;
    this.changes.totalCostAfter = this.modifiedBlanketOrder.orderTotal;
  }

  closeDialog(result: boolean) {
    this.dialogRef.close(result)
  }
}

export interface BlanketOrderChanges {
  addedItems: SalesOrderInventoryItem[]
  removedItems: SalesOrderInventoryItem[]
  modifiedItems: SalesOrderInventoryModifiedItem[]
  totalQuantityBefore?: number
  totalQuantityAfter?: number
  totalCostBefore?: number
  totalCostAfter?: number
}

export interface SalesOrderInventoryModifiedItem {
  itemNo: string
  displayName: string
  returnOrReplacement: string
  isFree: boolean
  quantityBefore: number
  quantityAfter: number
  unitPriceBefore: number
  unitPriceAfter: number
}