import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductDto } from 'src/app/services/api.services';

@Component({
  selector: 'app-blanket-order-new-item-options-dialog',
  templateUrl: './blanket-order-new-item-options-dialog.component.html',
  styleUrl: './blanket-order-new-item-options-dialog.component.scss'
})
export class BlanketOrderNewItemOptionsDialogComponent {

  selected: ProductDto

  options: BlanketOrderNewItemOptionsDialogOptions = {
    selectedAnyOptions: false,
    isFree: false,
    freeQuantity: 0
  }

  constructor(
    private dialogRef: MatDialogRef<BlanketOrderNewItemOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selected: ProductDto }
  ) {
    if (this.data) {
      this.selected = this.data.selected
    }
  }

  onConfirm() {
    this.options.selectedAnyOptions = true
    this.dialogRef.close(this.options)
  }

  onCancel() {
    this.options.selectedAnyOptions = false
    this.dialogRef.close(this.options)
  }
}

export interface BlanketOrderNewItemOptionsDialogOptions {
  selectedAnyOptions: boolean
  isFree: boolean
  freeQuantity: number
}
