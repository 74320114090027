import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BlanketOrderQuantityDistributionChoiceDialogComponent } from './blanket-order-quantity-distribution-choice-dialog.component';

@Injectable({ providedIn: 'root' })
export class QuantityDistributionChoiceDialogService {
    constructor(private dialog: MatDialog) { }

    showDialog(): Observable<'auto' | 'manual' | 'cancel'> {
        const dialogRef = this.dialog.open(BlanketOrderQuantityDistributionChoiceDialogComponent, {
            width: '60vw',
            maxHeight: '80vh',
            autoFocus: "false",
            disableClose: true
        });

        return dialogRef.afterClosed();
    }

}